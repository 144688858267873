import { IconButton, makeStyles, Typography } from "@material-ui/core"
import React, { useEffect, useState } from "react"
import CloseIcon from "@material-ui/icons/Close"
import DrawerTextField from "./utils/DrawerTextField"
import DrawerButton from "./utils/DrawerButton"
import { useDispatch, useSelector } from "react-redux"
import { profileApi } from "../../../api/apiRequests"
import apiClient from "../../../api/apiClient"
import Spinner from "../../components/Loader/Spinner"
import { setErrorAlert } from "../../../redux/slices/errorAlertSlice"
import ErrorAlert from "../../components/modals/errorAlert"

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  titleRoot: {
    width: "97%",
    display: "flex",
    marginTop: "40px",
    marginBottom: "30px",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginLeft: "15px",
  },
  rootForm: {
    width: "100%",
  },
  rootMainForm: {
    paddingLeft: "15px",
    paddingRight: "15px ",
  },
  rootButton: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    bottom: "0",
    right: "0",
    position: "absolute",
    marginBottom: "20px",
  },
  subButton: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    marginRight: "25px",
  },
  typoTitle: {
    fontFamily: "DM Sans",
    fontSize: "30px",
    fontWeight: 400,
  },
  errorText: {
    color: "#FD6E84",
    fontSize: "12px",
    marginLeft: "10px",
  },
}))

function ChangePassword({ close }) {
  const classes = useStyles()

  const dispatch = useDispatch()

  const user = useSelector(state => state.userSlice.user)

  const [currentPassword, setCurrentPassword] = useState()
  const [newPassword, setNewPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [isDisabled, setIsDisabled] = useState(true)
  const [currentPasswordError, setCurrentPasswordError] = useState(false)
  const [newPasswordError, setNewPasswordError] = useState(false)
  const [confirmPasswordError, setConfirmPasswordError] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (confirmPassword === newPassword) {
      setIsDisabled(false)
    } else {
      setIsDisabled(true)
      setConfirmPasswordError("Passwords does not match")
    }
  }, [confirmPassword])

  const validatePassword = (value, type) => {
    if (value.length < 6) {
      setIsDisabled(true)
      if (type === "current-password") {
        setCurrentPasswordError("Password must be at least 6 characters long")
        return
      }
      if (type === "new-password") {
        setNewPasswordError("Password must be at least 6 characters long")
        return
      }
      if (type === "confirm-password") {
        setConfirmPasswordError("Passwords does not match")
        return
      }
    } else {
      setCurrentPasswordError("")
      setNewPasswordError("")
      setConfirmPasswordError("")
    }
  }

  const handleCurrentPassword = e => {
    validatePassword(e.target.value, "current-password")
    setCurrentPassword(e.target.value)
  }

  const handleNewPassword = e => {
    validatePassword(e.target.value, "new-password")
    setNewPassword(e.target.value)
  }

  const handleConfirmPassword = e => {
    validatePassword(e.target.value, "confirm-password")
    setConfirmPassword(e.target.value)
  }

  const handleChangePassword = async () => {
    setLoading(true)

    try {
      const res = await apiClient(
        profileApi.changePassword({
          email: user.email,
          oldPassword: currentPassword,
          password: newPassword,
          rePassword: confirmPassword,
        })
      )
      close()
    } catch (error) {
      dispatch(
        setErrorAlert({
          isVisible: true,
          message:
            error.response?.data?.message ||
            "Error changing password. try again later",
          title: "error",
          severity: "error",
        })
      )
    }
    setLoading(false)
  }

  return (
    <div className={classes.root}>
      <ErrorAlert />
      <div className={classes.titleRoot}>
        <div>
          <Typography className={classes.typoTitle} style={{ margin: "13px" }}>
            Edit your password
          </Typography>
        </div>
        <IconButton onClick={close}>
          <CloseIcon style={{ margin: "13px" }} />
        </IconButton>
      </div>
      <div className={classes.rootForm}>
        <div className={classes.rootMainForm}>
          <div style={{ width: "100%" }}>
            <DrawerTextField
              errors={currentPasswordError}
              onChange={handleCurrentPassword}
              value={currentPassword}
              placeHolder="Current Password"
              label="Enter Current Password"
              type="password"
            />
          </div>
          <div style={{ width: "100%" }}>
            <DrawerTextField
              errors={newPasswordError}
              onChange={handleNewPassword}
              value={newPassword}
              placeHolder="New Password"
              label="Enter New Password"
              type="password"
            />
          </div>
          <Typography className={classes.errorText}>
            {newPasswordError}
          </Typography>
          <div style={{ width: "100%" }}>
            <DrawerTextField
              errors={confirmPasswordError}
              onChange={handleConfirmPassword}
              value={confirmPassword}
              placeHolder="Confirm Password"
              label="Re-enter New Password"
              type="password"
            />
          </div>
          <Typography className={classes.errorText}>
            {confirmPasswordError}
          </Typography>
          <div className={classes.rootButton}>
            <div className={classes.subButton}>
              {loading ? (
                <Spinner />
              ) : (
                <DrawerButton
                  text="Save"
                  handleOnClick={() => handleChangePassword()}
                  disabled={isDisabled}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ChangePassword
